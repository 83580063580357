var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('CCardHeader',{attrs:{"tag":"div"}},[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Thêm vùng kinh tế ")],1),_c('CCardBody',[_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tên vùng kinh tế","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Tên vùng kinh tế","horizontal":"","value":_vm.item.ten,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "ten", $event)}}})]}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Mã vùng kinh tế","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var touched = ref.touched;
return [_c('BaseInput',{attrs:{"label":"Mã vùng kinh tế","horizontal":"","value":_vm.item.ma,"is-valid":valid,"touched":touched,"invalid-feedback":errors},on:{"update:value":function($event){return _vm.$set(_vm.item, "ma", $event)}}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v("Trạng thái")]),_c('CCol',{staticClass:"form-inline",attrs:{"sm":"9"}},[_c('CInputCheckbox',{attrs:{"label":"Hoạt động","checked":_vm.item.trangThai},on:{"update:checked":function($event){return _vm.$set(_vm.item, "trangThai", $event)}}})],1)],1)],1)],1)],1),(_vm.isLoading)?_c('CElementCover'):_vm._e()],1),_c('CCardFooter',{staticClass:"text-right",attrs:{"tag":"div"}},[_c('CButton',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":_vm.cancel}},[_vm._v("Hủy bỏ")]),_c('CButton',{attrs:{"color":"primary","disabled":invalid || _vm.isSaving},on:{"click":function($event){return handleSubmit(_vm.saveItem)}}},[_vm._v("Lưu lại")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }