<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Thêm vùng kinh tế
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên vùng kinh tế" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên vùng kinh tế" horizontal :value.sync="item.ten" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Mã vùng kinh tế" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Mã vùng kinh tế" horizontal :value.sync="item.ma" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox label="Hoạt động" :checked.sync="item.trangThai"/>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { CREATE_VUNGKINHTE } from '@/store/modules/vung-kinh-te/actionTypes'

export default {
  name: 'VungKinhTeCreate',
  data () {
    return {
      optionsTrangThai: statuses,
      item: {
        ten: '',
        ma: '',
        trangThai: null
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('vungKinhTe', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('vungKinhTe', { createItem: CREATE_VUNGKINHTE }),
    async saveItem () {
      await this.createItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/vung-kinh-te' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/vung-kinh-te' })
    }
  },
  async mounted () {
    this.isLoading = true
    this.isLoading = false
  }
}
</script>
